import { useEffect, useState } from "react";
import "./style.scss";
export default function Animation() {
  const TypingAnimation = ({ text = "", speed = 50 }) => {
    const [displayedText, setDisplayedText] = useState("");

    useEffect(() => {
      if (displayedText.length < text.length) {
        const timeout = setTimeout(() => {
          setDisplayedText(text.substring(0, displayedText.length + 1));
        }, speed);
        return () => clearTimeout(timeout);
      }
    }, [displayedText, text, speed]);
    return <p className="title">{displayedText}</p>;
  };

  return (
    <div className="container animation_container">
      <div className="text_container">
        <TypingAnimation text="GambleGazette" />
        <p className="second_title">
          Your Ultimate Guide to the World of Gambling Insights
        </p>
      </div>
    </div>
  );
}
