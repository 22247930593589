export default function Hero() {
  return (
    <>
      <div
        className="baner_hero"
        style={{ backgroundImage: "url(/baner.png)" }}
      >
        <div className="text_container">
          <h1 className="title">
            Captivating Casino Nights: Dive into the World of Gambling with Our
            Latest News
          </h1>
          <p className="descr">
            Always in the rhythm of the latest gambling trends
          </p>
        </div>
      </div>

      <div
        className="text_poster"
        style={{ backgroundImage: "url(/text_poster.png)" }}
      >
        <p className="text">
          At GambleGazette, we're dedicated to keeping you ahead in the gambling
          world. Our team tirelessly curates the latest news, strategies, and
          insights, ensuring you're always in the know. From the freshest casino
          games to betting tips, we're your one-stop source for all things
          gambling. Trust us to elevate your gaming experience with expertly
          crafted content designed for enthusiasts by enthusiasts. Stay
          connected, stay informed, and let us guide you to your next big win
        </p>
      </div>
    </>
  );
}
