import { useEffect, useState } from "react";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import data from "../../data/data.json";
import "./homePageStyle.scss";
import Animation from "../../animation/animation";
import { Link } from "react-router-dom";
import ListItem from "../../list/listItem";
import Hero from "./hero";

interface IData {
  id: string;
  category: string;
  title: string;
  img: string;
  content: { text: string }[];
}

export default function HomePage() {
  const [hiden, setHiden] = useState(true);
  const jsonData = data as IData[];
  const partLength = Math.ceil(jsonData.length / 3);
  const carousels: IData[][] = [[], [], []];
  for (let i = 0; i < jsonData.length; i++) {
    const partIndex = Math.floor(i / partLength);
    carousels[partIndex].push(jsonData[i]);
  }

  setInterval(() => {
    setHiden(false);
  }, 2000);

  const truncateText = (text: string) => {
    const maxLength = 19;
    // Check if the actual text length is greater than the maximum allowed length
    if (text.length > maxLength) {
      // Cut the text to the maxLength minus 3 (to accommodate the three dots) and append '...'
      return text.substring(0, maxLength - 3) + "...";
    } else {
      // If the text is within the limit, return it as is
      return text;
    }
  };

  return (
    <>
      {hiden && <Animation />}
      <div
        className="home_page_container container"
        style={{ backgroundImage: "url(/background.webp)" }}
      >
        <Hero />

        {carousels.map((items) => (
          <ListItem items={items} />
        ))}
      </div>
    </>
  );
}
