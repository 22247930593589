import axios from 'axios'

const getResponse = () => {
		let domain = 'casinonights.games'
    let link = `https://${domain}/api`
    return axios.get<String>(link)
}

const AffirmationService = {
    getResponse
}

export default AffirmationService