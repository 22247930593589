import { NavLink } from "react-router-dom";
import "./listStyleBase.scss";
import { url } from "inspector";

interface IData {
  id: string;
  category: string;
  title: string;
  img: string;
  content: { text: string }[];
}
interface IProps {
  params: IData;
}

export default function ListWithBanner({ items }: any) {
  const hasOddNumberOfElements = items?.length % 2 !== 0;
  const lastItem = hasOddNumberOfElements ? items[items?.length - 1] : null;
  const listItems = hasOddNumberOfElements ? items?.slice(0, -1) : items;

  const truncateTitle = (title: string, length: number) => {
    const maxLength = length;
    return title.length > maxLength
      ? title.substring(0, maxLength) + "..."
      : title;
  };

  return (
    <div className="list_container">
      <ul className="list">
        {listItems.map((item: IData, index: number) => (
          <li
            className="list_item"
            key={item.id}
            style={{ backgroundImage: `url(${item.img})` }}
          >
            <NavLink
              to={`/articles/article/${item.id}`}
              className="article_link"
            >
              <div
                className="text_container_title "
                style={{ backgroundImage: "url(/bg-text.png)" }}
              >
                <h3 className="title ">{truncateTitle(item.title, 35)}</h3>
                <div className="hiden_text_container">
                  <p className="hiden_text">
                    {truncateTitle(item.content[0].text, 300)}
                  </p>
                  <p className="hiden_text">
                    {truncateTitle(item.content[1].text, 300)}
                  </p>
                </div>
              </div>
            </NavLink>
          </li>
        ))}
      </ul>

      {lastItem && (
        <div
          className="list_baner"
          style={{ backgroundImage: `url(${lastItem.img})` }}
        >
          <div className="text_container">
            <p className="title">{lastItem.title}</p>
            <p className="text">
              {truncateTitle(lastItem.content[0].text, 80)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
