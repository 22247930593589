import "./policyPageStyle.scss";
import dataText from "../../data/privacy.json";
export default function PolyciPage() {
  return (
    <div
      className="container polyci_container"
      style={{ backgroundImage: "url(/background.webp)" }}
    >
      <h2 className="title">Privacy Policy for Guide to Gold </h2>

      <div className="text_container">
        <p className="text">{dataText.privacy_policy}</p>
      </div>
    </div>
  );
}
